<template>
  <body id="pdf-body" style="background: #fff" v-if="contractData != null">
    <div class="control-container">
      <h3 class="control-header">Contract Controls</h3>
      
      <section class="item-contrainer">
        <section class="control-item">
          <b-form-group
            class=""
            label="簽名位置"
            label-for="sign_position"
            label-cols-md="4"
          >
            <b-form-input
              id="sign_position"
              placeholder="簽名位置"
              v-model="contractData.template_sign_position"
            />
          </b-form-group>
          <b-button variant="primary" style="width: 100%" @click="handlePrint"
            >Print</b-button
          >
          <b-button style="margin-top:10px; width:100%;" @click="handleClose">Close</b-button>
        </section>
      </section>
    </div>
    <div class="header">
      <img :src="contractLogoImage" width="auto" height="80px" />
    </div>
    <table style="width: 100%">
      <thead>
        <tr>
          <td>
            <div class="header-space">&nbsp;</div>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <div class="content">
              <div class="main">
                <div class="col-xs-12">
                  <p>Ref: <span v-if="contractData.contract_id">{{contractData.contract_id}}</span></p>
                </div>

                <h4 class="center underline">{{ contractData.template_main_title }}</h4>

                <div
                  class="col-xs-12 border"
                  style="margin-top: 10px; display: flex"
                >
                  <div class="col-xs-8 border">
                    <p>{{ contractData.language.party_a_title }}：</p>
                    <p>{{ contractData.language.party_a_address_title }}：></p>
                  </div>
                  <div class="col-xs-4 border">
                    <p>（{{ contractData.language.br_no_text }}.: ）</p>
                  </div>
                </div>
                <div class="col-xs-12 border" style="display: flex">
                  <div class="col-xs-8 border">
                    <p>
                      {{ contractData.language.party_b_title }}：
                      {{ contractData.language.party_b }}
                    </p>
                    <p>
                      {{ contractData.language.party_b_address_title }}：{{
                        contractData.language.party_b_address
                      }}
                    </p>
                  </div>
                  <div class="col-xs-4">
                    <p>
                      （{{ contractData.language.br_no_text }}.:
                      {{ contractData.language.br_no }}）
                    </p>
                  </div>
                </div>
                <div class="col-xs-12 border ontopmargin">
                  <p>{{ contractData.language.contract_description }}</p>
                </div>
                <div
                  class="col-xs-12 border ontopmargin article"
                  v-for="item in contractData.contents"
                  :key="item.id"
                >
                  <span class="title"
                    ><strong
                      >{{ item.content_sort_id }}
                      {{ item.content_title }}</strong
                    ></span
                  >
                  <div v-html="item.content_content"></div>
                </div>

                <div class="col-xs-12 border ontopmargin part12">
                  <p>
                    {{ contractData.language.agreement_description }}
                  </p>
                </div>

                <div
                  class="col-xs-12 border ontopmargin part13 article"
                  :style="{
                    display: 'flex',
                    'margin-top': contractData.template_sign_position + 'px',
                  }"
                >
                  <div class="col-xs-7">
                    <p>{{ contractData.language.party_a_title }}:</p>
                    <p></p>
                    <p>{{ contractData.language.signature_title }}:</p>
                    <div class="col-xs-12" style="min-height: 100px"></div>
                    <p>________________________________</p>
                    <p>{{ contractData.language.signature_name_title }}:</p>
                    <p>{{ contractData.language.signature_title_title }}:</p>
                    <p>
                      {{
                        contractData.language.signature_contact_number_title
                      }}: 
                    </p>
                  </div>
                  <div class="col-xs-5">
                    <p>
                      {{ contractData.language.party_b_title }}:{{
                        contractData.language.party_b
                      }}
                    </p>
                    <p></p>
                    <p>{{ contractData.language.signature_title }}:</p>
                    <div class="col-xs-12" style="min-height: 100px">
                      <img
                        :src="contractSignImage"
                        width="auto"
                        height="100px"
                      />
                    </div>
                    <p>________________________________</p>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td>
            <div class="footer-space">&nbsp;</div>
          </td>
        </tr>
      </tfoot>
    </table>
    <div class="footer" style="width: 100%" id="footerContent">
      <div class="footer-info">
        <!--
            <div class="col-xs-12" style="text-align:center; margin-top:30px; margin-bottom:20px;">
                <p>CS@UNICONSULTS.COM&nbsp;&nbsp;&nbsp;&nbsp;WWW.UNICONSULTS.COM</p>
                <p>UNIT A , 3/F, CHEONG SUN TOWER,116-118 WING LOK STREET,SHEUNG WAN, HONG KONG</p>
            </div>
            -->
        <div class="col-xs-12"><p style="font-weight: 800">{{contractData.language.address_title}}</p></div>
        <div class="col-xs-12" style="margin-bottom: 0px; padding-bottom: 0px">
          <p style="margin: 0px; padding: 0px" v-if="contractData.language.address1">{{contractData.language.address1}}</p>
        </div>
        <div class="col-xs-12" style="display: flex">
          <div class="col-xs-9" style="margin: 0; padding: 0">
            <p style="margin: 0px; padding: 0px" v-if="contractData.language.address2">
              {{contractData.language.address2}}
            </p>
            <p style="margin: 0px; padding: 0px" v-if="contractData.language.address3">{{contractData.language.address3}}</p>
          </div>
          <div class="col-xs-3" style="margin: 0; padding: 0">
            <p style="margin: 0px; padding: 0px; font-size: 1.1em">
              {{contractData.language.contract_email}}
            </p>
            <p
              style="
                margin: 0px;
                padding: 0px;
                font-size: 1.1em;
                font-weight: 800;
              "
            >
              {{contractData.language.contract_website}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </body>
</template>

<script>
import { $themeConfig } from "@themeConfig";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardText,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
} from "bootstrap-vue";
import router from "@/router";
import store from "@/store";
import { ref, onUnmounted, onmounted } from "@vue/composition-api";
import contractStoreModule from "@/views/contract/contractStoreModule";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      // showCustomizer: $themeConfig.layout.customizer,
      contractLogoImage: $themeConfig.app.contractLogoImage,
      contractSignImage: $themeConfig.app.contractSignImage,
    };
  },
  methods: {
    handleClose() {
      close()
    },
    handlePrint() {
      window.print();
    },
    
  },
  setup() {
    const contractData = ref({
        "id": 4,
        "template_name": "UI\/UX \u610f\u5411\u53ca\u78ba\u8a8d\u66f8",
        "template_remark": "\u610f\u5411\u53ca\u78ba\u8a8d\u66f8",
        "template_main_title": "\u610f\u5411\u53ca\u78ba\u8a8d\u66f8",
        "template_sign_position": 200,
        "create_id": 1,
        "contract_template_language_id": 1,
        "created_at": "2022-02-25T22:58:54.000000Z",
        "updated_at": "2022-02-25T22:58:54.000000Z",
        "language": {
            "id": 1,
            "language_name": "\u4e2d\u6587\u6a21\u7248",
            "language_remark": "\u539f\u751f\u4e2d\u6587\u6a21\u7248",
            "party_a_title": "\u7532\u65b9",
            "party_a_address_title": "\u7532\u65b9\u5730\u5740",
            "party_b_title": "\u4e59\u65b9",
            "party_b": "Unicorn Consultants Limited",
            "party_b_address_title": "\u4e59\u65b9\u5730\u5740",
            "party_b_address": "Rm09, 16\/F, Kwong Sang Hong Centre, Nos. 151-153 Hoi Bun Road, Kowloon, Hong Kong",
            "br_no_text": "BR No",
            "br_no": "71639662 - 000",
            "contract_description": "\u672c\u9805\u76ee\u5408\u4f5c\u5354\u8b70\u66f8\uff08\u4e0b\u7a31\u201c\u672c\u5354\u8b70\u201d\uff09\u65bc{{contract_date}}\uff08\u65e5\u671f\uff09\u7c3d\u8a02\u53ca\u751f\u6548\uff0c\u70ba\u671f24\u500b\u6708\u3002\u4ee5\u4e0b\u689d\u6b3e\u548c\u689d\u4ef6\u69cb\u6210\u7532\u4e59\u96d9\u65b9\n\uff08\u5171\u7a31\u70ba\u201c\u96d9\u65b9\u201d\uff09\u4e4b\u9593\u5c31\u5408\u4f5c\u5167\u5bb9\u9054\u6210\u5354\u8b70\uff1a",
            "agreement_description": "\u5982\u96d9\u65b9\u540c\u610f\u4e0a\u8ff0\u689d\u6b3e\uff0c\u9808\u65bc\u672c\u5354\u8b70\u6700\u5f8c\u4e00\u9801\u7c3d\u7f72\u53ca\u84cb\u5370\u4ee5\u8332\u8b49\u660e\u3002\n\u7d93\u96d9\u65b9\u540c\u610f\u5f8c\uff0c\u53ef\u4ee5\u65bc\u672c\u5354\u8b70\u4e0a\u65b0\u589e\u88dc\u5145\u5408\u540c\uff0c\u552f1\u8a72\u5408\u540c\u9700\u8981\u7d93\u96d9\u65b9\u7c3d\u7f72\u6838\u5be6\u5f8c\u65b9\u80fd\u751f\u6548\uff0c\u5982\u6709\u4efb\u4f55\u722d\u8b70\u53ca\u77db\u76fe\u5730\u65b9\uff0c\u61c9\u4ee5\u8f03\u65b0\u7c3d\u8a02\u8005\u70ba\u6e96\u3002",
            "signature_title": "\u6388\u6b0a\u4eba\u7c3d\u7f72\u53ca\u516c\u53f8\u84cb\u7ae0",
            "signature_name_title": "\u59d3\u540d",
            "signature_title_title": "\u8077\u929c",
            "signature_contact_number_title": "\u806f\u7d61\u96fb\u8a71",
            "address_title": "ADDRESS",
            "address1": "Room 9, 16\/F",
            "address2": "Kwong Sang Hong Centre, 151-153 Hoi Bun Road",
            "address3": "Kwun Tong, Kowloon",
            "contract_email": "cs@uniconsults.com",
            "contract_website": "www.uniconsults.com",
            "created_at": "2022-02-24T06:30:34.000000Z",
            "updated_at": "2022-02-26T19:38:03.000000Z"
        },
        "contents": [
            {
                "id": 37,
                "contract_template_id": 4,
                "content_sort_id": 1,
                "content_title": "\u96d9\u65b9\u8cac\u4efb",
                "content_content": "<p>\u4f5c\u70ba\u4e59\u65b9\u7684\u5ba2\u6236\uff0c\u7532\u65b9\u6709\u6b0a\u529b\u548c\u80fd\u529b\u4ee3\u8868\u60a8\u7684\u516c\u53f8\u6216\u7d44\u7e54\u7c3d\u8a02\u6b64\u5408\u540c\u3002\u7532\u65b9\u540c\u610f\u5411\u4e59\u65b9\u63d0\u4f9b\u5b8c\u6210\u9805\u76ee\u6240 \u9700\u7684\u4e00\u5207\uff0c\u5305\u62ec\u6587\u672c\uff0c\u5716\u50cf\u548c\u5176\u4ed6\u4fe1\u606f(\u5728\u9700\u8981\u6642\u4ee5\u53ca\u4ee5\u4e59\u65b9\u8981\u6c42\u7684\u683c\u5f0f)\u3002\u7532\u65b9\u540c\u610f\u5be9\u67e5\u4e59\u65b9\u7684\u5de5\u4f5c\uff0c \u53ca\u6642\u63d0\u4f9b\u53cd\u994b\u548c\u6279\u51c6\u7c3d\u5b57\u3002\u622a\u6b62\u65e5\u671f\u6709\u5169\u7a2e\u5de5\u4f5c\u65b9\u5f0f\uff0c\u7532\u65b9\u4e5f\u6703\u53d7\u5230\u4e59\u65b9\u8a2d\u5b9a\u7684\u4efb\u4f55\u65e5\u671f\u7684\u7d04\u675f\u3002<\/p><p><br><\/p><p>\u4e59\u65b9\u64c1\u6709\u8c50\u5bcc\u7684\u7d93\u9a57\u548c\u80fd\u529b\uff0c\u53ef\u4ee5\u70ba\u7532\u65b9\u63d0\u4f9b\u6240\u9700\u7684\u670d\u52d9\uff0c\u4e59\u65b9\u5c07\u4ee5\u5c08\u696d\uff0c\u53ca\u6642\u7684\u65b9\u5f0f\u9032\u884c\u3002\u5728\u6b64\u904e\u7a0b \u4e2d\uff0c\u4e59\u65b9\u5c07\u52aa\u529b\u6eff\u8db3\u6240\u6709\u898f\u5b9a\u7684\u622a\u6b62\u65e5\u671f\uff0c\u4f46\u662f\u5982\u679c\u7532\u65b9\u9072\u9072\u6c92\u6709\u63d0\u4f9b\u6750\u6599\u6216\u5728\u4efb\u4f55\u968e\u6bb5\u90fd\u672a\u6309\u6642\u6279\u51c6 \u6216\u7c3d\u7f72\u4e59\u65b9\u7684\u5de5\u4f5c\uff0c\u4e59\u65b9\u5c07\u4e0d\u70ba\u932f\u904e\u7684\u555f\u52d5\u65e5\u671f\u6216\u622a\u6b62\u65e5\u671f\u8ca0\u8cac\u3002 \u6700\u91cd\u8981\u7684\u662f\uff0c\u4e59\u65b9\u9084\u5c07\u7dad\u8b77\u7532\u65b9\u63d0 \u4f9b\u7d66\u4e59\u65b9\u7684\u4efb\u4f55\u4fe1\u606f\u7684\u6a5f\u5bc6\u6027\uff0c\u8a72\u4fe1\u606f\u5728\u672c\u5354\u8b70\u7d42\u6b62\u6216\u671f\u6eff\u5f8c\u4ecd\u5c07\u7e7c\u7e8c\u3002<\/p>",
                "created_at": "2022-02-25T22:58:54.000000Z",
                "updated_at": "2022-02-25T22:58:54.000000Z"
            },
            {
                "id": 38,
                "contract_template_id": 4,
                "content_sort_id": 2,
                "content_title": "\u5de5\u4f5c\u7d30\u7bc0",
                "content_content": "<p>\u4e59\u65b9\u5c07\u4ee5\u6709\u7d44\u7e54\u7684\u5f62\u5f0f\u91cd\u65b0\u8a2d\u8a08\u548c\u5be6\u65bd\u65b0\u8a2d\u8a08\uff0c\u5f9e\u800c\u4f7f\u64f4\u5c55\u524d\u7aef\u8b8a\u5f97\u5bb9\u6613\u4e14\u6613\u65bc\u7dad\u8b77\u3002\u8a72\u9805\u76ee\u5c07\u63a1\u7528\u91dd \u5c0d\u79fb\u52d5\u8a2d\u5099\u7684\u79fb\u52d5\u8a2d\u8a08\u512a\u5148\u65b9\u6cd5\u5b8c\u6210\u3002<\/p><p><br><\/p><p>\u8a72\u5408\u540c\u5305\u62ec\u4e00\u500b\u4e3b\u8981\u8a2d\u8a08\uff0c\u4ee5\u53ca\u7532\u65b9\u9032\u884c\u5169\u8f2a\u4fee\u8a02\u7684\u6a5f\u6703\u3002\u5982\u679c\u7532\u65b9\u5c0d\u73fe\u968e\u6bb5\u7684\u8a2d\u8a08\u4e0d\u6eff\u610f\uff0c\u8acb\u76e1\u65e9\u63d0 \u51fa\uff0c\u4e26\u6e05\u695a\u8aaa\u660e\u9700\u8981\u4fee\u6539\u7684\u5167\u5bb9\u3002<\/p>",
                "created_at": "2022-02-25T22:58:54.000000Z",
                "updated_at": "2022-02-25T22:58:54.000000Z"
            },
            {
                "id": 39,
                "contract_template_id": 4,
                "content_sort_id": 3,
                "content_title": "\u9805\u76ee\u6210\u54e1",
                "content_content": "<p>TitleNameUI Designer<\/p><p>UX Designer<\/p><p>Project ManagerBrian Yip<\/p>",
                "created_at": "2022-02-25T22:58:54.000000Z",
                "updated_at": "2022-02-25T22:58:54.000000Z"
            },
            {
                "id": 40,
                "contract_template_id": 4,
                "content_sort_id": 4,
                "content_title": "\u8a2d\u8a08\u6982\u8ff0",
                "content_content": "<p><span style=\"color: rgb(0, 0, 0);\">\u5728\u6b64\u8655\u5217\u51fa\u8a73\u7d30\u4fe1\u606f<\/span><\/p><p><br><\/p><p><br><\/p>",
                "created_at": "2022-02-25T22:58:54.000000Z",
                "updated_at": "2022-02-25T22:58:54.000000Z"
            },
            {
                "id": 41,
                "contract_template_id": 4,
                "content_sort_id": 5,
                "content_title": "\u6587\u5b57\u5167\u5bb9",
                "content_content": "<p><span style=\"color: rgb(0, 0, 0);\">\u9664\u975e\u4e59\u65b9\u5728\u539f\u59cb\u4f30\u7b97\u4e2d\u6307\u5b9a\u4e86\u6587\u5b57\u526f\u672c\uff0c\u5426\u5247\u4e59\u65b9\u4e0d\u8ca0\u8cac\u7de8\u5beb\u6216\u8f38\u5165\u4efb\u4f55\u6587\u5b57\u526f\u672c\u3002\u4e0d\u904e\uff0c\u4e59\u65b9\u5f88\u6a02\u610f\u70ba\u7532\u65b9\u63d0\u4f9b\u5e6b\u52a9\uff0c\u9664\u4e86\u4f30\u7b97\u8cbb\u7528\u5916\uff0c \u4e59\u65b9\u9084\u5c07\u6309\u5c0f\u6642$ 100 \u6536\u8cbb\uff0c\u5305\u62ec\u514d\u8cbb\u7684\u521d\u6b65\u8aee\u8a62\uff0c\u62f7\u8c9d\u5beb\u4f5c\u6216\u5167\u5bb9\u8f38\u5165\u3002<\/span><\/p>",
                "created_at": "2022-02-25T22:58:54.000000Z",
                "updated_at": "2022-02-25T22:58:54.000000Z"
            },
            {
                "id": 42,
                "contract_template_id": 4,
                "content_sort_id": 6,
                "content_title": "\u5716\u7247",
                "content_content": "<p><span style=\"color: rgb(0, 0, 0);\">\u5982\u679c\u9700\u8981\uff0c\u7532\u65b9\u5c07\u4ee5\u6578\u78bc\u683c\u5f0f\u5411\u6211\u5011\u63d0\u4f9b\u7167\u7247\u3002\u5982\u679c\u7532\u65b9\u9078\u64c7\u8cfc\u8cb7\u7167\u7247\uff0c\u4e59\u65b9\u53ef\u4ee5\u5efa\u8b70\u7167\u7247\u7684\u4f9b\u61c9\u5546\u3002 \u5982\u679c\u5728\u8a2d\u8a08\u904e\u7a0b\u4e2d\u9700\u8981\u7167\u7247\uff0c\u4e59\u65b9\u5c07\u6dfb\u52a0\u4f54\u4f4d\u7b26\u4e26\u7b49\u5f85\u63d0\u4f9b\u7167\u7247\u3002<\/span><\/p>",
                "created_at": "2022-02-25T22:58:54.000000Z",
                "updated_at": "2022-02-25T22:58:54.000000Z"
            },
            {
                "id": 43,
                "contract_template_id": 4,
                "content_sort_id": 7,
                "content_title": "\u8b8a\u66f4\u548c\u4fee\u8a02",
                "content_content": "<p>\u4e59\u65b9\u5c07\u8207\u7532\u65b9\u7dca\u5bc6\u5408\u4f5c\uff0c\u63d0\u4f9b\u4e00\u7cfb\u5217\u4fee\u8a02\uff0c\u4ee5\u78ba\u4fdd\u96d9\u65b9\u5c0d\u6700\u7d42\u7522\u54c1\u90fd\u6eff\u610f\u3002\u5728\u6574\u500b\u53c3\u8207\u904e\u7a0b\u4e2d\u53ef\u63a5\u53d7\u7684 \u4fee\u8a02\u70ba:<\/p><p>\u2022 2 \u6b21\u63d0\u4ea4\u9801\u9762\u5f8c\u66f4\u6539\u7e3d\u9ad4\u8a2d\u8a08\u3002\u9019\u5305\u62ec\u6574\u9ad4\u9801\u9762\u4f48\u5c40\u548c\u6837\u5f0f\u3002<\/p><p>\u2022 \u53ef\u4ee5\u96a8\u6642\u63d0\u4ea4\u984f\u8272\uff0c\u5b57\u9ad4\u5927\u5c0f\uff0c\u586b\u5145\uff0c\u908a\u8ddd\u548c\u5143\u7d20\u7684\u8f03\u5c0f\u4f4d\u7f6e\u66f4\u6539\u7b49\u8f03\u5c0f\u66f4\u6539\uff0c\u4e26\u4e14\u7121\u9700\u4ed8\u8cbb\u3002<\/p><p>\u5982\u679c 2 \u6b21\u4fee\u6539\u5f8c\uff0c\u7532\u65b9\u60f3\u8981\u6dfb\u52a0\u984d\u5916\u7684\u9801\u9762\u6216\u6a21\u677f\uff0c\u751a\u81f3\u6dfb\u52a0\u65b0\u529f\u80fd\uff0c\u8acb\u4ee5\u66f8\u9762\u5f62\u5f0f\u63d0\u4ea4\u3002\u6536\u5230\u6b63\u5f0f\u8acb \u6c42\u5f8c\uff0c\u5c07\u70ba\u7532\u65b9\u63d0\u4f9b\u4e0a\u8ff0\u66f4\u6539\u7684\u5831\u50f9\u3002\u6b64\u985e\u66f4\u6539\u5c07\u6309\u56fa\u5b9a\u7684\u5c0f\u6642\u8cbb\u7387\u6536\u53d6\uff0c\u4e14\u4e0d\u5f97\u8d85\u904e\u56fa\u5b9a\u7684\u65e5\u8cbb\u7387\u3002<\/p><p>\u5728\u5c65\u884c\u672c\u5408\u540c\u671f\u9593\u6216\u5728\u4efb\u4f55\u6642\u5019\u4e59\u65b9\u5411\u7532\u65b9\u63d0\u4f9b\u670d\u52d9\u6642\uff0c\u4e59\u65b9\u7684\u6bcf\u5c0f\u6642\u8cbb\u7387\u5c07\u70ba\u6bcf\u5c0f\u6642$ 100\uff0c\u800c\u4e59\u65b9 \u7684\u6bcf\u65e5\u8cbb\u7387\u5728\u4efb\u4f55\u60c5\u6cc1\u4e0b\u90fd\u4e0d\u6703\u8d85\u904e\u6bcf\u5929$ 800.00\u3002<\/p>",
                "created_at": "2022-02-25T22:58:54.000000Z",
                "updated_at": "2022-02-25T22:58:54.000000Z"
            },
            {
                "id": 44,
                "contract_template_id": 4,
                "content_sort_id": 8,
                "content_title": "\u6642\u9593",
                "content_content": "<p><span style=\"color: rgb(0, 0, 0);\">\u6211\u5011\u9810\u7b97\u5728 6-8 \u9031\u5167\u5b8c\u6210\u8a72\u9805\u76ee\u7684 UX&amp;UI Design \u90e8\u5206\u3002\u524d\u7aef\u4ee3\u78bc\uff0c\u9ad4\u7cfb\u7d50\u69cb\u8a2d\u8a08\u548c\u5be6\u73fe\u9810\u8a08\u5728 2-3 \u500b \u6708\u5167\u5b8c\u6210\u3002<\/span><\/p>",
                "created_at": "2022-02-25T22:58:54.000000Z",
                "updated_at": "2022-02-25T22:58:54.000000Z"
            },
            {
                "id": 45,
                "contract_template_id": 4,
                "content_sort_id": 9,
                "content_title": "\u6cd5\u5f8b",
                "content_content": "<p><span style=\"color: rgb(0, 0, 0);\">\u672c\u5354\u8b70\u7684\u689d\u6b3e\u53d7\u9999\u6e2f\u7279\u5225\u884c\u653f\u5340\u6cd5\u4f8b\u76e3\u7ba1\uff0c\u4e26\u64da\u6b64\u4e88\u4ee5\u8a6e\u91cb\u3002\u53e6\u6b64\u5408\u4f5c\u5354\u8b70\u66f8\u5099\u6709\u82f1\u6587\u7248\u672c\uff0c\u6982\u4ee5\u4e2d\u6587 \u7248\u70ba\u6e96\u3002\u5982\u6709\u4efb\u4f55\u722d\u8b70\uff0cUnicorn Consultants Limited \u4fdd\u7559\u6700\u7d42\u6c7a\u5b9a\u6b0a\u3002<\/span><\/p>",
                "created_at": "2022-02-25T22:58:54.000000Z",
                "updated_at": "2022-02-25T22:58:54.000000Z"
            }
        ]
    });

    const CONTRACT_STORE_MODULE_NAME = "contract";
    if (!store.hasModule(CONTRACT_STORE_MODULE_NAME))
      store.registerModule(CONTRACT_STORE_MODULE_NAME, contractStoreModule);

    onUnmounted(() => {
      if (store.hasModule(CONTRACT_STORE_MODULE_NAME))
        store.unregisterModule(CONTRACT_STORE_MODULE_NAME);
    });


    if (router.currentRoute.name == "contracts-demo-preview") {
      store
        .dispatch(CONTRACT_STORE_MODULE_NAME + "/fetchDemoContract", {
          id: router.currentRoute.params.id,
        })
        .then((response) => {
          contractData.value = response.data.contract;
          console.log("contractData", contractData.value);
        })
        .catch((error) => {});
    }

    return {
      contractData,
    };
  },
};
</script>

<style lang="scss" >
body {
  width: 210mm;
  margin: auto;
  background: #fff;
}
html {
  background: #fff;
  margin: 0;
  padding: 0;
}

.header {
  width: 100%;
  margin: auto;
  text-align: center;
}
.content {
  margin-left: 0 !important;
}

* {
  font-family: "Times New Roman";
  font-size: 12px;
}
.footerheader {
  font-family: "Calibri Light", serif;
  font-size: 11.9px;
  color: rgb(190, 165, 98);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}

.footerp {
  font-family: "Calibri Light", serif;
  font-size: 10.1px;
  color: rgb(190, 165, 98);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}

table.report-container {
  page-break-after: always;
}

thead.report-header {
  display: table-header-group;
}

tfoot.report-footer {
  display: table-footer-group;
}

.title {
  display: block;
  margin: 9px 0;
}

@page: last {
  tfoot.report-footer {
    display: table-footer-group;
    position: fixed;
    bottom: 0;
  }
}
table.report-container div.article {
  page-break-inside: avoid;
}

@keyframes animate-fade-in-top {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

@mixin fadeintop($duration, $delay) {
  animation-name: animate-fade-in-top;
  animation-duration: $duration;
  animation-delay: $delay;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}

.control-container {
  position: fixed;
  z-index: 999999;
  padding: 15px;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 5px;
  right: 25px;
  top: 25px;
  width: 405px;
  box-shadow: 5px 5px 10px #000000;
  opacity: 0;
  max-height: calc(100vh - 50px);
  @include fadeintop(0.3s, 0.2s);

  .control-header {
    margin-bottom: 15px;
    padding-bottom: 5px;
    border-bottom: 1px solid #ccc;
  }

  .item-container {
    flex: 1 1 auto;
    overflow: auto;
    margin-bottom: 10px;

    .control-item {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      .control {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        padding-bottom: 5px;
        border-bottom: 1px solid #ccc;
        width: 100%;

        .item-label {
          margin-right: 15px;
          display: block;
          padding-bottom: 3px;
          font-weight: 600;
          flex-shrink: 0;
        }

        .input-checkbox {
          width: 20px;
          height: 20px;
          cursor: pointer;
        }

        .input-box {
          flex: 1 1 auto;
          padding: 5px;
          border-radius: 3px;
          border: 1px solid #bbb;
          width: 0;
        }
      }

      .options-container {
        width: 100%;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;

        .options-item {
          &:not(:last-child) {
            margin-bottom: 5px;
          }

          .options-title {
            margin-right: 10px;
            font-weight: 600;
          }

          .options-value {
            color: #3574b3;
          }
        }
      }
    }
  }

  .generate-btn {
    border: 0;
    padding: 15px 25px;
    border-radius: 5px;
    color: #fff;
    background: #243b55;
    transition: 0.3s;
    text-align: center;
    cursor: pointer;

    &:disabled {
      opacity: 0.5;
    }
  }

  .progress-container {
    width: 100%;
    margin-top: 10px;

    .progress-bar {
      width: 100%;
      height: 10px;
      border: 1px solid #585858;
      border-radius: 5px;
      margin-bottom: 5px;

      .progress {
        width: 0%;
        height: 100%;
        transition: 0.3s;
        background: #2c3e50;
      }
    }

    .generating-label {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
}
@media only screen and (max-width: 600px) {
  .control-container {
    width: 100%;
    position: unset;
  }
}

@media print {
  .control-container {
    display: none;
  }

  * {
    font-family: "Times New Roman";
    font-size: 18px !important;
  }
  body {
    -webkit-print-color-adjust: exact;
  }
  table.report-container {
    page-break-after: always;
  }

  thead.report-header {
    display: table-header-group;
  }

  tfoot.report-footer {
    display: table-footer-group;
  }
  .title {
    display: block;
    margin: 9px 0;
  }
  table.report-container div.article {
    page-break-inside: avoid;
  }
  .header {
    position: fixed;
    top: 0;
  }

  .footer {
    position: fixed;
    bottom: 0;
  }

  .header,
  .header-space,
  .footer,
  .footer-space {
    height: 120px;
  }
  .footer-info p {
    color: #a6863c !important;
  }
  P {
    font-size: 12px;
  }
}

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  border: 1px solid #ddd;
}

.center {
  text-align: center;
}

.underline {
  text-decoration: underline;
}

.border {
}

P {
  font-size: 12px;
}

.ontopmargin {
  margin-top: 20px;
}

.text-spacing {
  padding-left: 10px;
  padding-top: 5px;
}

.upperborder {
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  height: 30px;
}

.upperrightborder {
  border-top: 1px solid black;
  border-right: 1px solid black;
  height: 30px;
}

.footer-info p {
  color: #a6863c;
}
.col-xs-12 {
  width: 100%;
}
.col-xs-9 {
  width: 75%;
}
.col-xs-7 {
  width: 58%;
}
.col-xs-3 {
  width: 25%;
}
.col-xs-8 {
  width: 77.7%;
}
.col-xs-5 {
  width: 42%;
}
.col-xs-4 {
  width: 33.3%;
}

.border {
  border: none !important;
}
</style>